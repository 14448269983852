import React, { Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Terms from "../Terms/Terms"

const TermsPage = () => {
  return (
    <Fragment>
      <Terms />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO pageTitle="Terms & Conditions" description="Our Terms & Conditions" />
    <TermsPage />
  </Layout>
)
