import React from "react"

function Terms() {
  return (
    <>
      <div className="columns" style={{ marginBottom: "40px" }}>
        <div className="column is-2-desktop is-2-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-8-tablet is-12-mobile">
          <p
            style={{
              fontSize: "2.5rem",
              fontWeight: "700",
              borderBottom: "2px solid #fec600",
              marginTop: "15px",
            }}
          >
            Terms of Use
          </p>
          <p
            style={{
              margin: "12px 0",
            }}
          >
            <strong>Last updated: May 22, 2021</strong>
          </p>

          <div
            style={{
              fontSize: "16px",
              marginTop: "25px",
              //color: "#333333",
            }}
            className="content"
          >
            <p>
              The following terms and conditions govern the use of all Content,
              Services, and Products (the "Service(s)") provided by voyay!ge
              Inc. ("voyay!ge") and available on the voyay!ge websites (and the
              voyay!ge mobile application(s) (the "Site"). The Service is owned
              and operated by voyay!ge. Voyay!ge is an online platform intended
              to be used by travel clients ("Client(s)") and travel designers
              ("Designer(s)") in connection with the Services. The Service is
              offered subject to your acceptance without modification of all of
              the terms and conditions contained herein and all other operating
              rules, policies, and procedures that may be published from time to
              time on the Site (collectively, the "Agreement"). <br></br>{" "}
              <br></br> If you are entering into this Agreement on behalf of a
              company or other legal entity, you represent that you have the
              authority to bind such entity, its affiliates, and all users who
              access the Service through your account to this Agreement, in
              which case the terms "you" or "your" shall refer to such entity,
              its affiliates, and users associated with it. If you do not have
              such authority, or if you do not agree with these terms and
              conditions, you must not accept this Agreement and may not use the
              Service. <br></br> <br></br>{" "}
              <strong>
                PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OUR SITE
                OR USING THE SERVICE. BY ACCESSING OR USING ANY PART OF THE
                SITE, YOU AGREE TO BECOME BOUND BY THIS AGREEMENT. THESE TERMS
                INCLUDE A BINDING ARBITRATION CLAUSE AND A CLASS ACTION WAIVER.
              </strong>{" "}
              <br></br>
              <br></br> If you do not agree to all the terms and conditions of
              this Agreement, then you may not access the Service. If these
              terms and conditions are considered an offer by voyay!ge,
              acceptance is expressly limited to these terms. <br></br>{" "}
              <br></br>{" "}
              <strong>
                VOYAY!GE IS A PLATFORM AND IS NOT A PARTY TO ANY SERVICES
                AGREEMENT OR OTHER TRANSACTION BETWEEN USERS OF THE SITE.
                <br></br> <br></br> Voyay!ge is Only a Platform.
              </strong>{" "}
              Voyay!ge acts as a marketplace for users of the Site as members
              Clients and Designers (Clients and Designers, each a "User" and
              together, "Users") to view and post services on the Site. Voyay!ge
              is not a party to any actual agreement or transaction between
              Clients and Designers even though we may from time to time provide
              tools that relate to communication. As a result, voyay!ge has no
              control over the accuracy or correctness of the Content or
              information provided or used by such Designers and Clients. Any
              part of an actual or potential transaction between a Designer and
              a Client, including the condition, quality, safety of the services
              advertised, the truth or accuracy of the Service (including the
              Content thereof or any review related thereto), the ability of
              Designers to provide services, or the ability of Clients to pay
              for services are solely the responsibility of each User. <br></br>{" "}
              <br></br>Without limitation, Users are solely responsible for:
              <ol type="a">
                <li>
                  ensuring the accuracy and legality of any User Content;{" "}
                </li>
                <li>
                  determining the suitability of other Users for a Service
                  Contract (such as any interviews, vetting, background checks,
                  or similar actions);{" "}
                </li>
                <li>
                  negotiating, agreeing to, and executing any terms or
                  conditions of Service Contracts;{" "}
                </li>
                <li>
                  performing Designer services; and/or paying for Designer
                  services.{" "}
                </li>
              </ol>
              You further acknowledge, agree, and understand that you are solely
              responsible for assessing whether to enter into a service contract
              with another User and for verifying any information about another
              User. Voyay!ge does not make any representations about or
              guarantee the truth or accuracy of any Designer's or Client's
              listings or other User Content on the Site; does not verify any
              feedback or information provided by Users about Designers or
              Clients; and does not perform background checks on or guarantee
              the work of Designer or Clients. You acknowledge, agree, and
              understand that voyay!ge does not, in any way, supervise, direct,
              control, or evaluate Designers or their work and is not
              responsible for any project, project terms or work product.
              <br></br> <br></br> You also acknowledge, agree, and understand
              that Designers are solely responsible for determining, and have
              the sole right to determine, which projects to accept; the time,
              place, manner, and means of providing any Designer services and
              the type of services they provide. You further acknowledge, agree,
              and understand that:
              <ol type="i">
                <li>
                  you are not an employee of voyay!ge, and you are not eligible
                  for any of the rights or benefits of employment (including
                  unemployment and/or workers compensation insurance);{" "}
                </li>
                <li>
                  voyay!ge will not have any liability or obligations for any
                  acts or omissions by you or other Users;{" "}
                </li>
                <li>
                  voyay!ge does not, in any way, supervise, direct, or control
                  any Designer or Designer services; does not impose quality
                  standards; and does not dictate the performance, methods or
                  process Designer uses to perform services;{" "}
                </li>
                <li>
                  Designer is free to determine when and if to perform Designer
                  services, including the days worked and time periods of work,
                  and voyay!ge does not set or have any control over Designer's
                  work hours, work schedules, or work location;{" "}
                </li>
                <li>
                  voyay!ge does not, in any way, provide or guarantee Designer a
                  regular salary or any minimum, regular payment;{" "}
                </li>
                <li>
                  voyay!ge does not provide Designers with training or any
                  equipment, labor, tools, or materials;
                </li>
                <li>
                  voyay!ge does not provide the premises at which Designers will
                  perform the work; and
                </li>
                <li>
                  unless otherwise agreed with their Client, Designers may use
                  subcontractors or employees to perform Designer services. If a
                  Designer uses subcontractors or employees, the Designer
                  further agrees and acknowledges that this Section applies to
                  voyay!ge's relationship, if any, with Designer's
                  subcontractors and employees as well and Designer is solely
                  responsible for Designer's subcontractors and employees.
                </li>
              </ol>
            </p>
            <h2 className="title is-3 margin-top-15px">
              Designer Account Terms
            </h2>{" "}
            <h3 className="title is-4 margin-top-15px">
              Providing Services on voyay!ge
            </h3>
            <p>
              As a Designer, voyay!ge offers you the opportunity to share your
              love for travel, with our vibrant community of Clients—and earn
              money doing it. It's easy to create a listing and you are in
              control of how you design travel plan—set you availability, and
              where and how you work. <br></br> <br></br> Contracting with
              Clients - When you receive a booking confirmation through the
              voyay!ge platform, you are entering into a contract directly with
              the Client, and are responsible for delivering the travel plan
              under the terms and at the price specified for the Service on the
              voyay!ge platform. You are also agreeing to pay applicable fees
              like voyay!ge service fee (and applicable taxes) for each booking.
              Voyay!ge's third-party payment provider will deduct amounts you
              owe from your payout unless we and you agree to a different
              method. Any terms, policies or conditions that you include in any
              supplemental contract with Client must:{" "}
              <ol type="i">
                <li>
                  be consistent with these Terms, our policies, and the
                  information provided in your listing, and{" "}
                </li>
                <li> be prominently disclosed in your listing description.</li>
              </ol>
              You are required to accept or decline booking within{" "}
              <strong>two (2) calendar days</strong>
              and complete the travel plan within{" "}
              <strong>seven (7) calendar days.</strong>
              <br></br> <br></br>
              Voyay!ge also requires you complete any requested changes to the
              travel plan within <strong>three (3) calendar days.</strong>
            </p>
            <h2 className="title is-3 margin-top-15px">
              General Account Terms
            </h2>{" "}
            <p>
              You must be a human. Accounts registered by “bots” or other
              automated methods are not permitted. <br></br> <br></br>You are
              responsible for maintaining the security of your account and
              password. Voyay!ge cannot and will not be liable for any loss or
              damage from your failure to comply with this security obligation.
              You are fully responsible for all activities that occur under the
              account and any other actions taken in connection with the
              account. You must immediately notify voyay!ge of any unauthorized
              uses of your account, or any other breaches of security. Voyay!ge
              will not be liable for any acts or omissions by you, including any
              damages of any kind incurred as a result of such acts or
              omissions. You may not use the Service for any illegal or
              unauthorized purpose. You must not, in the use of the Service,
              violate any laws in your jurisdiction (including but not limited
              to copyright or trademark laws).
            </p>
            <h2 className="title is-3 margin-top-15px">Content</h2>{" "}
            <p>
              If you make (or allow any third party to make) material available
              through the Site (any such material, “Content”), you are entirely
              responsible for the content of, and any harm resulting from, that
              Content. That is the case regardless of whether the Content in
              question constitutes text, graphics, an audio file, URLs, links to
              third party sites or computer software.
              <br></br> <br></br>By making Content available, you represent and
              warrant that: <br></br>
            </p>
            <ul>
              <li>
                downloading, copying, and use of the Content will not infringe
                the proprietary rights, including but not limited to the
                copyright, patent, trademark, or trade secret rights, of any
                third party;
              </li>
              <li>
                if your employer has rights to intellectual property you create,
                you have either (i) received permission from your employer to
                post or make available the Content, including but not limited to
                any software, or (ii) secured from your employer a waiver as to
                all rights in or to the Content;
              </li>
              <li>
                you have fully complied with any third-party licenses relating
                to the Content, and have done all things necessary to
                successfully pass through to end users any required terms;
              </li>
              <li>
                the Content does not contain or install any viruses, worms,
                malware, trojan horses, or other harmful or destructive content;
              </li>
              <li>
                the Content is not spam, is not machine- or randomly-generated,
                and does not contain unethical or unwanted commercial content
                designed to drive traffic to third party sites or boost the
                search engine rankings of third party sites, or to further
                unlawful acts (such as phishing) or mislead recipients as to the
                source of the material (such as spoofing);
              </li>
              <li>
                the Content is not pornographic, does not contain threats or
                incite violence, and does not violate the privacy or publicity
                rights of any third party;
              </li>
              <li>
                the Content is not getting advertised via unwanted electronic
                messages such as spam links on newsgroups, email lists, other
                groups and web sites, and similar unsolicited promotional
                methods;
              </li>
              <li>
                the Content is not named in a manner that misleads your readers
                into thinking that you are another person or company;
              </li>
              <li>
                you have, in the case of Content that includes computer code,
                accurately categorized and/or described the type, nature, uses,
                and effects of the materials, whether requested to do so by
                voyay!ge or otherwise; and
              </li>
              <li>
                you agree that you will not submit any media that contains
                personally identifiable information (like name, phone number,
                email address or web site URL) of you or of anyone else.
                Uploading media like images or video of other people without
                their permission is strictly prohibited.
              </li>
              <li>
                Without limiting any of those representations or warranties,
                voyay!ge has the right (though not the obligation) to, in
                voyay!ge’s sole discretion:
              </li>
              <li>
                refuse or remove any content that, in voyay!ge’ s reasonable
                opinion, violates any voyay!ge policy or is in any way harmful
                or objectionable, or
              </li>
              <li>
                terminate or deny access to and use of the Service to any
                individual or entity for any reason. voyay!ge will have no
                obligation to provide a refund of any amounts previously paid.
              </li>
            </ul>
            <p>
              As a user, you may deactivate your account at any time. Personally
              identifying information such as your name, username, and profile
              photo will no longer be associated with Content you have posted.
            </p>
            <h2 className="title is-3 margin-top-15px">Interactive Services</h2>{" "}
            <p>
              We provide interactive services on our Site, including, without
              limitation:
            </p>
            <ul>
              <li>Chat rooms</li>
              <li>Bulletin boards.</li>
            </ul>
            <p>
              Our Site has an interactive messaging system between users. We do
              not moderate this service. Should a difficulty arise, the user
              should contact us by emailing{" "}
              <a
                className="link"
                href="mailto: reach-out@voyayge.com"
                //   style={{ textDecoration: "none", color: "#4a4a4a" }}
              >
                reach-out@voyayge.com
              </a>{" "}
              immediately. <br></br> <br></br> We will take reasonable endeavors
              to assess any possible risks for users from third parties when
              they use any interactive service provided on our Site, and we will
              decide in each case whether it is appropriate to use moderation of
              the relevant Service (including what kind of moderation to use) in
              the light of those risks. However, we are under no obligation to
              oversee, monitor or moderate any interactive service we provide on
              our Site, and we expressly exclude our liability for any loss or
              damage arising from the use of any interactive service by a user
              in contravention of our content standards, whether the Service is
              moderated or not.
            </p>
            <h2 className="title is-3 margin-top-15px">Minimum Age</h2>{" "}
            <p>
              The Services are intended solely for persons who are 18 or older.
              Any access to or use of the Services by anyone under 18 is
              expressly prohibited. By accessing or using the Services you
              represent and warrant that you are 18 or older.
            </p>
            <h2 className="title is-3 margin-top-15px">
              Fees, Cancellation and Refund Policy
            </h2>{" "}
            <p>
              Client agrees to pay voyay!ge the fees indicated for the Service.
              You will be charged on a pre-pay basis and will cover the Service
              as indicated. Designer Fees are non-refundable unless:
            </p>
            <ul>
              <li>
                Service is cancelled by a Client within{" "}
                <strong>twenty-four (24) hours</strong>
                of signing up for the Service,
              </li>
              <li>
                the Designer is not able to complete the travel plan within{" "}
                <strong>seven (7) calendar days,</strong>
              </li>
              <li>
                the Designer is not able to complete requested changes to the
                travel plan within <strong>three (3) calendar days</strong>, if
                a Designer cancels Designer services for any reason, Designer
                will issue full refund.{" "}
              </li>
            </ul>
            <p>
              Platform Fees are non-refundable. <br></br> <br></br> Voyay!ge
              does not guarantee the suitability or availability of any of the
              activities, accommodations and flight recommendations and
              therefore voyay!ge will not be issuing refund for these reasons.{" "}
              <br></br> <br></br> Please email
              <a
                className="link"
                href="mailto: support@voyayge.com"
                //   style={{ textDecoration: "none", color: "#4a4a4a" }}
              >
                support@voyayge.com
              </a>{" "}
              to request a refund or a cancellation.
            </p>
            <h2 className="title is-3 margin-top-15px">Payments</h2>{" "}
            <p>
              Voyay!ge currently uses third party to process payments. Our
              third- party payment processors accept payments through various
              credit cards, as detailed on the applicable payment screen. All
              monetary transactions on the Service take place in U.S. Dollars.
            </p>
            <h2 className="title is-3 margin-top-15px">Taxes and Benefits</h2>{" "}
            <p>
              Designer acknowledges and agrees that Designer is solely
              responsible
              <ol type="a">
                <li>
                  for all tax liability associated with payments received from
                  Designer’s Clients and through voyay!ge, and that voyay!ge
                  will not withhold any taxes from payments to Designer;
                </li>
                <li>
                  to obtain any liability, health, workers’ compensation,
                  disability, unemployment, or other insurance needed, desired,
                  or required by law, and that Designer is not covered by or
                  eligible for any insurance from voyay!ge;{" "}
                </li>
                <li>
                  for determining whether Designer is required by applicable law
                  to issue any particular invoices for the Designer fees and for
                  issuing any invoices so required;{" "}
                </li>
                <li>
                  for determining whether Designer is required by applicable law
                  to remit to the appropriate authorities any value added tax or
                  any other taxes or similar charges applicable to the Designer
                  fees and remitting any such taxes or charges to the
                  appropriate authorities, as appropriate; and
                </li>
                <li>
                  if outside of the United States, for determining if voyay!ge
                  is required by applicable law to withhold any amount of the
                  Designer fees and for notifying voyay!ge of any such
                  requirement and indemnifying voyay!ge for any requirement to
                  pay any withholding amount to the appropriate authorities
                  (including penalties and interest). In the event of an audit
                  of voyay!ge, Designer agrees to promptly cooperate with
                  voyay!ge and provide copies of Designer’s tax returns and
                  other documents as may be reasonably requested for purposes of
                  such audit, including but not limited to records showing
                  Designer is engaging in an independent business as represented
                  to voyay!ge.
                </li>
              </ol>
            </p>
            <h2 className="title is-3 margin-top-15px">Platform Fee</h2>{" "}
            <p>
              Voyay!ge charges Designers and Clients a platform fee of 25%
              (“Platform Fees”) from of the entire service fee for facilitating
              the booking. Voyay!ge reserves the right to adjust its Platform
              Fees at any time.
            </p>
            <h2 className="title is-3 margin-top-15px">Copyright Policy</h2>{" "}
            <p>
              Voyay!ge respects copyright law and expects its users to do the
              same. It is voyay!ge’s policy to terminate in appropriate
              circumstances users or other account holders who repeatedly
              infringe or are believed to be repeatedly infringing the rights of
              copyright holders.
            </p>
            <h2 className="title is-3 margin-top-15px">
              Intellectual Property Ownership
            </h2>{" "}
            <p>
              The Services contain proprietary materials such as logos, text,
              software, photos, video, graphics, music and sound. Voyay!ge is
              the owner or licensee of all such proprietary material and all
              intellectual property rights therein, including without
              limitation, copyrights and trademarks. In using the Services, you
              agree not to upload, download, distribute, or reproduce in any way
              any such proprietary materials without obtaining voyay!ge’ prior
              consent. <br></br> <br></br> By uploading any information related
              to the travel plan, including but not limited to itinerary,
              destination and activity information, and media on the Site like a
              photo or video:{" "}
              <ol type="a">
                <li>
                  you grant to voyay!ge a perpetual, irrevocable, non-exclusive,
                  worldwide, royalty-free, fully sublicensable right and license
                  to use, copy, print, publicly display, reproduce, modify,
                  publish, post, transmit, create derivative works from, and
                  distribute the media and any material included in the media;
                </li>
                <li>
                  you certify that any person pictured in the submitted media
                  (or, if a minor, his/her parent/legal guardian) authorizes
                  voyay!ge to use, copy, print, publicly display, reproduce,
                  modify, publish, post, transmit, create derivative works from,
                  and distribute the media and any material included in such
                  media; and
                </li>
                <li>
                  you agree to indemnify voyay!ge and its affiliates, directors,
                  officers and employees and hold them harmless from any and all
                  claims and expenses, including attorneys' fees, arising from
                  the media and/or your failure to comply with these Terms.
                </li>
              </ol>
              <br></br> Voyay!ge reserves the right to review all media prior to
              submission to the site and to remove any media for any reason, at
              any time, without prior notice, at our sole discretion.
            </p>
            <h2 className="title is-3 margin-top-15px">Changes</h2>{" "}
            <p>
              We reserve the right to modify these terms at any time and
              modified terms are effective upon posting to the Site. We will
              make reasonable efforts to inform you of these changes via email
              or through the Service. Your continued use of or access to the
              Service following the posting of any changes to this Agreement
              constitutes acceptance of those changes. Voyay!ge may also, in the
              future, offer new services and/or features including the release
              of new tools and resources. Such new features and/or services
              shall be subject to the terms and conditions of this Agreement.
            </p>
            <h2 className="title is-3 margin-top-15px">Data</h2>
            <p>
              Users may request data related to their personal account and
              group(s) respectively. Voyay!ge has the right to refuse repetitive
              or technically difficult requests. For information about how we
              collect and share user information please refer to our Privacy
              Policy.
            </p>
            <h2 className="title is-3 margin-top-15px">
              Records of Compliance
            </h2>{" "}
            <p>
              Users will each (a) create and maintain records to document
              satisfaction of their respective obligations under this Agreement,
              including, without limitation, their respective payment
              obligations and compliance with tax and employment laws. You are
              solely responsible for creation, storage, and backup of your
              business records. This Agreement and any registration for or
              subsequent use of the Site will not be construed as creating any
              responsibility on voyay!ge’s part to store, backup, retain, or
              grant access to any information or data for any period.
            </p>
            <h2 className="title is-3 margin-top-15px">
              General Representation and Warranty
            </h2>{" "}
            <p>You represent and warrant that:</p>
            <ul>
              <li>
                your use of the Service will be in strict accordance with all
                voyay!ge policies and procedures, with this Agreement, and with
                all applicable laws and regulations (including without
                limitation any local laws or regulations in your country, state,
                city, or other governmental area, regarding online conduct and
                acceptable content),
              </li>
              <li>
                your use of the Service will not infringe or misappropriate the
                intellectual property rights of any third party.
              </li>
            </ul>
            <h2 className="title is-3 margin-top-15px">
              Disclaimer of Warranties
            </h2>{" "}
            <p>
              <>
                THE SERVICE IS PROVIDED “AS IS” AND "AS AVAILABLE". VOYAY!GE AND
                ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES,
                AFFILIATES, LICENSORS AND SUPPLIERS HEREBY EXPRESSLY DISCLAIM
                ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING,
                WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE,
                MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR
                PURPOSE. NEITHER VOYAY!GE NOR ITS OFFICERS, DIRECTORS,
                EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES, LICENSORS NOR
                SUPPLIERS WARRANT THAT THE OPERATION OF THE SERVICE WILL BE
                UNINTERRUPTED, ERROR FREE OR WITHOUT BREACHES OF SECURITY.
                <br></br> <br></br>VOYAY!GE, ITS OFFICERS, DIRECTORS, EMPLOYEES,
                AGENTS, SUBSIDIARIES, AFFILIATES, AND ITS LICENSORS DO NOT
                REPRESENT OR WARRANT THAT:
                <ul>
                  <li>THE INFORMATION PROVIDED ON SITE IS TRUE OR CORRECT,</li>
                  <li>
                    YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS OR
                    EXPECTATIONS,
                  </li>
                  <li>
                    YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
                    SECURE OR FREE FROM ERROR,
                  </li>
                  <li>
                    ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF
                    THE SERVICES WILL BE ACCURATE OR RELIABLE, OR
                  </li>
                  <li>
                    THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY
                    SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE
                    CORRECTED.
                  </li>
                </ul>
              </>
            </p>
            <h2 className="title is-3 margin-top-15px">
              Limitation of Liability
            </h2>{" "}
            <p>
              <>
                IN NO EVENT WILL VOYAY!GE ITS OFFICERS, DIRECTORS, EMPLOYEES,
                AGENTS, SUBSIDIARIES OR AFFILIATES BE LIABLE TO ANY PARTY FOR:
                <ol type="A">
                  <li>
                    ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                    EXEMPLARY, OR PUNITIVE, DAMAGES ARISING OUT OF THE USE,
                    AVAILABILITY OR UNAVAILABILITY OF THE SERVICES (INCLUDING
                    BUT NOT LIMITED TO THE UNAVAILABILITY OR CHANGE IN
                    ADVERTISED SERVICES) OR ANY OTHER SERVICES OR GOODS OBTAINED
                    THROUGH USE OF ANY OF THE FOREGOING, OR ANY DATA TRANSMITTED
                    THROUGH THE SERVICE, EVEN IF VOYAY!GE HAS BEEN ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGES; AND
                    <ol type="a">
                      <li>
                        ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU,
                        INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT
                        OF:
                      </li>
                      <li>
                        ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY
                        OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY
                        RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY
                        ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE
                        SERVICES;
                      </li>
                      <li>
                        ANY CHANGES WHICH VOYAY!GE MAY MAKE TO THE SERVICES, OR
                        FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE
                        PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE
                        SERVICES);
                      </li>
                      <li>
                        YOUR FAILURE TO PROVIDE VOYAY!GE WITH ACCURATE ACCOUNT
                        INFORMATION;
                      </li>
                      <li>
                        YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS
                        SECURE AND CONFIDENTIAL.
                      </li>
                    </ol>
                  </li>
                  <li>
                    ANY BODILY INJURY, DEATH, OR PROPERTY DAMAGE THAT MAY OCCUR
                    AS THE RESULT OF SERVICES.
                  </li>
                  <li>
                    NOTWITHSTANDING THE TERMS, IN NO EVENT SHALL VOYAY!GE BE
                    HELD LIABLE, FOR ANY AMOUNTS THAT EXCEED THE FEES PAID BY
                    YOU TO VOYAY!GE UNDER THIS AGREEMENT DURING THE SIX (6)
                    MONTH PERIOD PRIOR TO THE CAUSE OF ACTION. VOYAY!GE SHALL
                    HAVE NO LIABILITY FOR ANY FAILURE OR DELAY DUE TO MATTERS
                    BEYOND THEIR REASONABLE CONTROL. THE FOREGOING SHALL NOT
                    APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                  </li>
                </ol>
              </>
            </p>
            <h2 className="title is-3 margin-top-15px">Indemnification</h2>{" "}
            <p>
              <>
                IN THE EVENT OF A DISPUTE BETWEEN A DESIGNER AND A CLIENT,
                DESIGNER AND CLIENT, JOINTLY AND SEVERALLY, HEREBY AGREE TO
                INDEMNIFY, RELEASE, DEFEND AND HOLD HARMLESS VOYAY!GE AND ITS
                DIRECTORS, OFFICERS, AGENTS AND EMPLOYEES (COLLECTIVELY, THE
                “VOYAY!GE PARTIES”) FROM ANY CLAIMS BROUGHT AS A RESULT OF
                CLIENT AND/OR DESIGNER’S (1) BREACH OF THE TERMS, (2) VIOLATION
                OF ANY LAW OR THE RIGHTS OF A THIRD PARTY, AND (3) USE OF THIS
                SITE AND THE SERVICES.
              </>
            </p>
            <h2 className="title is-3 margin-top-15px">Disputes Resolution</h2>{" "}
            <p>
              This Agreement constitutes the entire agreement between voyay!ge
              and you concerning the subject matter hereof, and they may only be
              modified by a written amendment signed by an authorized
              agent/employee of voyay!ge, or by the posting by voyay!ge of a
              revised version. Any and all disputes arising of this Agreement,
              termination, or our relationship with you shall be determined by
              applying laws of the State of Nevada. <br></br> <br></br>
              If any part of this Agreement is held invalid or unenforceable,
              that part will be struck, and the remaining portions will remain
              in full force and effect. A waiver by either party of any term or
              condition of this Agreement or any breach thereof, in any one
              instance, will not waive such term or condition or any subsequent
              breach thereof. You may not assign your rights under this
              Agreement without written consent from voyay!ge; voyay!ge may
              assign its rights under this Agreement without condition. This
              Agreement will be binding upon and will inure to the benefit of
              the parties, their successors and permitted assigns.
            </p>
            <h2 className="title is-3 margin-top-15px">ARBITRATION</h2>{" "}
            <h3 className="title is-4 margin-top-15px">
              Dispute resolution and arbitration
            </h3>{" "}
            <p>
              By using this Site, you agree that any and all disputes, claims or
              controversies that you may have against voyay!ge arising out of or
              relating to or connected in any way to (i) your use of this Site
              (ii) any services obtained from or provided by voyay!ge or (iii)
              this Agreement (including the interpretation and scope of this
              clause and the arbitrability of the dispute), shall be resolved
              exclusively by mandatory, binding arbitration initiated through
              and administered by the American Arbitration Association ("AAA").
              You further agree that arbitration will be conducted by a single
              arbitrator pursuant to the applicable Rules and Procedures
              established by AAA, and that any arbitration proceeding, if
              necessary, will be held in Las Vegas, Nevada or at such other
              location as may be mutually agreed upon by voyay!ge and you.
              Arbitration will be subject to the Federal Arbitration Act and not
              any state arbitration law. The arbitrator shall apply Nevada law
              consistent with the Federal Arbitration Act, and shall honor
              claims of privilege recognized at law. In order to initiate
              arbitration with the AAA, you may be responsible for paying a
              filing fee to the AAA, however, in the event you are able to
              demonstrate that the costs of arbitration will be prohibitive as
              compared to the costs of litigation, voyay!ge will reimburse as
              much of your filing and hearing fees in connection with the
              arbitration as the arbitrator deems necessary to prevent the
              arbitration from being cost-prohibitive. There will be no right or
              authority for any claims to be arbitrated on a class action or
              representative basis.{" "}
              <strong>
                YOU UNDERSTAND AND AGREE THAT, BY ACCEPTING THESE TERMS AND
                CONDITIONS, YOU AND VOYAY!GE ARE EACH WAIVING THE RIGHT TO A
                TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION WITH RESPECT
                TO THE CLAIMS COVERED BY THIS MANDATORY ARBITRATION PROVISION.
              </strong>{" "}
              You are thus giving up your right to go to court to assert or
              defend your rights. Your rights will be determined by a neutral
              arbitrator, and not a judge or jury. <br></br> <br></br>All claims
              you bring against voyay!ge must be resolved in accordance with
              this arbitration provision. All claims filed or brought by you
              contrary to this provision will be considered improperly filed and
              void. Should you file a claim contrary to this arbitration
              provision, voyay!ge will notify you in writing of the improperly
              filed claim, and you must promptly withdraw the claim. If you fail
              to promptly withdraw the claim after receiving written notice from
              voyay!ge, voyay!ge may recover its reasonable attorneys’ fees and
              costs incurred to enforce this arbitration provision.
            </p>
            <h3 className="title is-4 margin-top-15px">Notice; Process</h3>{" "}
            <p>
              A party who intends to seek arbitration must first send a written
              notice of the dispute to the other, by certified mail, Federal
              Express, UPS, or Express Mail (signature required), or in the
              event that we do not have a physical address on file for you, by
              electronic mail ("Notice"). Voyay!ge's address for Notice is: 3049
              Villanelle Ave., Henderson, NV 89044. <br></br> <br></br>The
              Notice must (1) describe the nature and basis of the claim or
              dispute; and (2) set forth the specific relief sought ("Demand").
              We agree to use good faith efforts to resolve the claim directly,
              but if we do not reach an agreement to do so within 30 days after
              the Notice is received, you or voyay!ge may commence an
              arbitration proceeding. During the arbitration, the amount of any
              settlement offer made by you or voyay!ge shall not be disclosed to
              the arbitrator until after the arbitrator makes a final decision
              and award, if any. All documents and information disclosed in the
              course of the arbitration shall be kept strictly confidential by
              the recipient and shall not be used by the recipient for any
              purpose other than for purposes of the arbitration or the
              enforcement of the arbitrator’s decision and award and shall not
              be disclosed except in confidence to persons who have a need to
              know for such purposes or as required by applicable law.
            </p>
            <h3 className="title is-4 margin-top-15px">Enforceability</h3>{" "}
            <p>
              If any part of this arbitration provision is deemed to be invalid,
              unenforceable or illegal, or otherwise conflicts with the Rules
              and Procedures established by AAA, then the balance of this
              arbitration provision shall remain in effect and shall be
              construed in accordance with its terms as if the invalid,
              unenforceable, illegal or conflicting provision was not contained
              herein.
            </p>
            <h2 className="title is-3 margin-top-15px">Miscellaneous</h2>{" "}
            <h3 className="title is-4 margin-top-15px">
              General Compliance with Laws
            </h3>{" "}
            <p>
              You shall comply with all applicable laws, statutes, ordinances
              and regulations regarding your use of the Services.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Headings</h3>{" "}
            <p>
              Headings are for reference purposes only and in no way define,
              limit, construe or describe the scope or extent of such section.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Entire Agreement </h3>{" "}
            <p>
              This Agreement constitutes the entire agreement between voyay!ge
              and you concerning the subject matter hereof, and they may only be
              modified by a written amendment signed by an authorized
              agent/employee of voyay!ge, or by the posting by voyay!ge of a
              revised version.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Governing Law</h3>{" "}
            <p>
              The terms of this Agreement shall be construed in accordance with
              the substantive laws of the State of Nevada, United States of
              America, without giving effect to the principles of conflict or
              choice of law of such state. The original form of this Agreement
              has been written in English. The parties hereto waive any statute,
              law, or regulation that might provide an alternative law or forum
              or might require this Agreement to be written in any language
              other than English. This Agreement expressly excludes the United
              Nations Convention on Contracts for the International Sale of
              Goods.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Severability</h3>{" "}
            <p>
              If any part of this Agreement is held invalid or unenforceable,
              that part will be struck, and the remaining portions will remain
              in full force and effect.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Waiver</h3>{" "}
            <p>
              A waiver by either party of any term or condition of this
              Agreement or any breach thereof, in any one instance, will not
              waive such term or condition or any subsequent breach thereof.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Assignment </h3>{" "}
            <p>
              You may not assign your rights under this Agreement without
              written consent from Voyay!ge; Voyay!ge may assign its rights
              under this Agreement without condition. This Agreement will be
              binding upon and will inure to the benefit of the parties, their
              successors and permitted assigns.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Termination</h3>{" "}
            <p>
              Voyay!ge may immediately issue a warning, temporarily suspend,
              indefinitely suspend or terminate your use of all or any portion
              of the Services if you breach the Terms, or at any time or for any
              reason, at the sole discretion of voyay!ge.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">No Relationship</h3>{" "}
            <p>
              No joint venture, partnership, employment, or agency relationship
              exist between you, voyay!ge or any third party provider as a
              result of this Agreement or use of the Service.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Non-Endorsement</h3>{" "}
            <p>
              Voyay!ge does not recommend one Designer over another and does not
              provide advice to Clients regarding which Designer to select. All
              comments or reviews posted by voyay!ge users, or Clients on our
              Site do not represent the views of voyay!ge in any way and are not
              endorsed by voyay!ge absent a specific written statement to the
              contrary.
            </p>{" "}
            <h3 className="title is-4 margin-top-15px">Force Majeure</h3>{" "}
            <p>
              Voyay!ge shall not be liable for any delay or failure to perform
              in connection with any Services, Service or in the performance of
              any obligation hereunder, if such delay or failure is due to or in
              any manner caused by acts of God, rebellion, riots, hijacking,
              insurrection, civil commotion, strikes or labor disputes, fires,
              floods, laws, regulations, acts, demands or and orders of any
              government or agency, illness or incapacitation of Designer, or
              any other cause which is beyond the control of voyay!ge. Voyay!ge
              is hereby released by you from any claim or demand for any direct
              or consequential damages arising out of failure to perform as a
              result of a force majeure event whether or not herein enumerated
              or other event referenced above
            </p>
            <h1 className="title is-2 margin-top-15px">Contact Us</h1>
            <p>
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <ul>
              <li>
                By email:{" "}
                <a
                  className="link"
                  href="mailto: reach-out@voyayge.com"
                  //   style={{ textDecoration: "none", color: "#4a4a4a" }}
                >
                  reach-out@voyayge.com
                </a>
              </li>
              <li>
                By visiting this page on our website:{" "}
                <a
                  className="link"
                  href="https://www.voyayge.com/contact"
                  //   style={{ textDecoration: "none", color: "#4a4a4a" }}
                >
                  https://www.voyayge.com/contact
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="column is-2-desktop is-2-tablet is-hidden-mobile"></div>
      </div>
    </>
  )
}

export default Terms
